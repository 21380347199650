import Vue from 'vue'
import Storage from 'vue-ls'

const options = {
  namespace: 'retailer_portal.', // key prefix
  name: 'storage', // name variable Vue.[ls] or this.[$ls],
  storage: 'session' // storage type: session, local, memory
}

export default Vue.use(Storage, options)
