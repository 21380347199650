<template>
  <div v-if="showItems">
    <div v-for="item in items" :key="item.id">
      <v-card outlined>
        <v-card-title class="">
          {{ item.order_item.title }}
        </v-card-title>
        <v-card-text class="card-text">
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <div
                class="image-wrapper"
                :class="{
                  'image-wrapper-large': $vuetify.breakpoint.mdAndUp,
                }"
                :style="{
                  'background-image': `url(${item.order_item.image_url})`,
                  width: '100%',
                  height: '250px',
                }"
              >
                <v-img
                  class="logo ml-2 mb-2"
                  :class="{
                    'logo-large': $vuetify.breakpoint.lg,
                  }"
                  :src="determineLogoPath(item) || determineLogoPath(rmaBrand)"
                  :max-width="'75px'"
                  alt="product-image"
                  contain
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="5"
              class="item-details"
            >
              <div><span class="label">Order:</span><br>{{ orderId }}</div>
              <div><span class="label">SKU:</span><br>{{ item.sku }}</div>
              <div>
                <span class="label">Reason:</span><br>
                <span v-if="item.return_reason && item.return_reason.name">
                  {{ item.return_reason.name }}
                </span>
                <span
                  v-else-if="
                    item.return_reason &&
                      item.return_reason.return_reason_feedback
                  "
                >
                  {{ item.return_reason.return_reason_feedback }}
                </span>
              </div>
              <div
                v-if="
                  item.order_item.variants &&
                    item.order_item.variants.length > 0
                "
              >
                <div
                  v-for="attribute in item.order_item.variants[0].attributes"
                  :key="attribute.name"
                >
                  <span class="label">{{ attribute.name }}:</span><br>{{
                    attribute.value
                  }}
                </div>
              </div>
              <div>
                <span class="label">Shipped:</span><br>{{
                  getShippedDate(item)
                }}
              </div>
              <div>
                <span class="label">Status:</span><br>{{ getStatus(item) }}
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="item-price"
            >
              {{
                convertCentsToDollars(
                  item.order_item.calculated_product_amount_cents
                )
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CurrencyHelper from '@/utils/currency'
export default {
  name: 'OrderItems',
  data () {
    return {
      statuses: {
        created: 'Created',
        received: 'Received',
        canceled: 'Canceled',
        not_received: 'Not Received'
      }
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    orderId: {
      type: String,
      default: undefined
    },
    rmaBrand: {
      type: String,
      required: false,
      default: ''
    },
    rmaKeep: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showItems () {
      return this.items?.length > 0
    }
  },
  methods: {
    convertCentsToDollars: (cents) => CurrencyHelper.convertCentsToDollars(cents),
    getStatus (item) {
      if (this.rmaKeep) {
        return 'Customer Keep'
      } else if (item.is_refunded) {
        return 'Refunded'
      } else {
        return item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1)
      }
    },
    getShippedDate (item) {
      return item.order_item.shipped_date ? new Date(item.order_item.shipped_date).toLocaleString() : ''
    },
    determineLogoPath (item) {
      if (item?.concept) {
        return require(`@/branding/${item.concept}-logo.png`).default
      }
      if (item?.order_item?.concept) {
        return require(`@/branding/${item.order_item.concept}-logo.png`).default
      }
      if (this.rmaBrand) {
        return require(`@/branding/${this.rmaBrand}-logo.png`).default
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.card-text {
  color: black !important;
  font-weight: bold;
}
.card-text .label {
  color: #9e9e9e !important;
  font-weight: normal;
}
.label {
  display: inline-block;
  min-width: 3.2rem;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.item-details > div {
  margin: 0 0 1rem 0;
}

.item-price {
  font-size: 1.5rem;
  text-align: right;
  margin-top: 10px;
}

.image-wrapper {
  background-size: contain !important;
  background-position: left;
  display: flex;
}

.logo {
  z-index: 1 !important;
  margin-top: auto !important;
  background-position: left !important;
  max-width: 50px;
  max-height: 50px;
}
.logo-large{
  margin-bottom: 26px !important;
}

</style>
