<template>
  <v-container id="app">
    <v-row>
      <v-col class="d-flex align-center flex-wrap heading">
        <v-col>
          <h1>RMA Management</h1>
        </v-col>
        <v-btn
          class="ml-auto"
          type="button"
          @click="downloadAsCSV()"
        >
          Export all as CSV
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container class="search-container pa-8" color="#F0F0F0">
          <v-row class="align-center">
            <v-col
              lg="2"
              md="2"
              sm="2"
              class="pr-0"
            >
              <search-select
                :items="searchCategories"
                :default-value="searchCategories[0]"
                @change="updateSelectedSearchCategory"
              />
            </v-col>
            <v-col
              xl="5"
              lg="4"
              md="3"
              class="pb-0 ml-4"
              :class="{ 'mb-n4': isStatusCategorySelected() }"
            >
              <v-text-field
                v-if="!isStatusCategorySelected()"
                v-model="search"
                :label="'Search by ' + selectedCategory.text || ''"
                @keyup.enter="searchRMAsForDashboard()"
                class="pt-0"
                hide-details
              >
                <template #prepend-inner>
                  <v-icon class="mr-2">
                    mdi-magnify
                  </v-icon>
                </template>
              </v-text-field>

              <template v-else>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Search by Date"
                      readonly
                      hide-details
                      class="pt-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <v-icon class="mr-2">
                          mdi-calendar
                        </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :selected-items-text="'test'"
                    :show-current="false"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </template>
              <div class="date-label">
                {{ determineFieldSuffix() }}
              </div>
            </v-col>

            <v-col
              class="flex-grow-0"
            >
              <v-btn
                @click="searchRMAsForDashboard()"
                :disabled="determineLookupEligibility()"
              >
                <v-icon class="mr-2">
                  mdi-magnify
                </v-icon>
                Lookup Return
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              class="d-flex flex-grow-0"
              :class="{
                'ml-auto': $vuetify.breakpoint.xl
              }"
            >
              <search-select
                class="filter mr-3"
                :class="{
                  'filter-small': $vuetify.breakpoint.mdAndDown,
                }"
                v-if="brandOptions && brandOptions.length > 1"
                :label="'BRAND'"
                :items="brandOptions"
                @change="updateBrandFilter"
                clearable
              />
              <search-select
                class="filter"
                :class="{
                  'filter-small': $vuetify.breakpoint.mdAndDown,
                }"
                :label="'RETURN TYPE'"
                :items="returnTypes"
                @change="updateReturnTypeFilter"
                clearable
              />
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :no-data-text="'No RMAs found.'"
          :no-results-text="'No items found for that search.'"
          :loading="isLoading"
          :loading-text="'Fetching RMAs...'"
          class="elevation-1"
          @click:row="onRowClick"
        >
          <template #[`item.identifier`]="{ item }">
            <span>{{ item.identifier }}</span>
          </template>
          <template #[`item.created_at`]="{ item }">
            <span>{{ new Date(item.created_at).toLocaleString() }}</span>
          </template>
          <template #[`item.updated_at`]="{ item }">
            <span>{{ new Date(item.updated_at).toLocaleString() }}</span>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip :color="getStatusColor(item.parentCategory)" text>
              {{ sanitizeStatus(item.status) }}
            </v-chip>
          </template>
          <template #[`item.refundAmount`]="{ item }">
            <strong>{{ `${item.refundAmount}` }}</strong><br>
          </template>
          <template #[`item.exchangeRefundAmount`]="{ item }">
            <strong>{{ `${item.exchangeRefundAmount}` }}</strong><br>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONSTANTS } from '@/constants'
import RMAService from '@/services/rma.service'
import RetailersService from '@/services/retailers.service'
import CurrencyHelper from '@/utils/currency'
import DownloadHelper from '@/utils/download'
import RMAStatusHelper from '@/utils/rma-status'
import SearchSelect from '@/components/common/SearchSelect.vue'

export default {
  name: CONSTANTS.COMPONENTS.DASHBOARD.NAME,
  components: {
    SearchSelect
  },
  data () {
    return {
      rawRMA: [],
      items: [],
      selectedCategory: {},
      search: '',
      date: '',
      modal: '',
      activeFilter: CONSTANTS.FILTER_STATES.ALL,
      filterStates: CONSTANTS.FILTER_STATES,
      isLoading: false,
      selectedFilters: {
        brand: '',
        isGiftReturn: ''
      },
      brandOptions: [],
      returnTypes: [{ value: false, text: 'Standard return' }, { value: true, text: 'Gift return' }],
      searchCategories: [
        { value: 'identifier', text: 'RMA ID (Exact Match)' },
        {
          value: 'exchange_order_identifier',
          text: 'Exchange Order ID (Exact Match)'
        },
        { value: 'order_id', text: 'Order ID (Exact Match)' },
        { value: 'shopper_email', text: 'Customer Email (Exact Match)' },
        { value: 'tracking_number', text: 'Tracking number (Exact Match)' },
        {
          value: 'first_name',
          text: 'Customer First Name (Minimum 3 characters)'
        },
        {
          value: 'last_name',
          text: 'Customer Last Name (Minimum 3 characters)'
        },
        { value: 'status_all', text: 'Status - All' },
        { value: 'status_pending', text: 'Status - Pending' },
        { value: 'status_needs_review', text: 'Status - Needs Review' },
        { value: 'status_needs_completed', text: 'Status - Completed' }
      ],
      headers: [
        { value: 'identifier', text: 'RMA ID' },
        {
          value: 'created_at',
          text: 'Created',
          filterable: false
        },
        {
          value: 'updated_at',
          text: 'Last Updated',
          filterable: false
        },
        {
          value: 'concept',
          text: 'Brand',
          filterable: false
        },
        { value: 'order_id', text: 'Order ID' },
        {
          value: 'combinedReturnMethods',
          text: 'Method',
          filterable: false
        },
        {
          value: 'itemCount',
          text: 'Items',
          filterable: false
        },
        { value: 'customerName', text: 'Customer Name' },
        { value: 'shopper.email', text: 'Customer Email' },
        {
          value: 'status',
          text: 'State',
          filterable: false
        },
        {
          value: 'refundAmount',
          text: 'Refund',
          filterable: false
        },
        {
          value: 'exchangeRefundAmount',
          text: 'Exchange Subtotal',
          filterable: false
        }
      ]
    }
  },
  mounted () {
    this.isLoading = false
    this.selectedCategory = this.searchCategories[0]
    this.date = this.getMonthDateFromToday()
    this.brandOptions = this.getBrandOptions()
  },
  computed: {},
  methods: {
    shouldShowRMA: (filter, category) => {
      return filter === category || filter === CONSTANTS.FILTER_STATES.ALL
    },
    rmaList: function () {
      const list = []

      if (this.rawRMA && this.rawRMA.length) {
        this.rawRMA.map((rma) => {
          const item = rma
          item.parentCategory = RMAStatusHelper.getParentCategory(item.status)

          if (
            this.shouldShowRMA(this.activeFilter, item.parentCategory) &&
            rma.rma_items
          ) {
            item.itemCount = rma.rma_items.length
            item.refundAmount = CurrencyHelper.convertCentsToDollars(
              rma.refund_estimate?.total_refund_cents
            )
            item.exchangeRefundAmount = CurrencyHelper.convertCentsToDollars(
              rma.refund_estimate?.exchange_subtotal_cents
            )
            item.combinedReturnMethods = rma.rma_items[0]?.return_method?.type === 'customer_keep' ? 'Keep' : rma.rma_items[0]?.return_method?.name

            item.customerName = `${rma.shopper?.first_name || ''} ${
              rma.shopper?.last_name || ''
            }`
            list.push(item)
          }
        })
      }
      this.items = list
    },
    onRowClick: function (rma) {
      this.$router.push({
        name: CONSTANTS.COMPONENTS.RMA_DETAIL.NAME,
        params: {
          id: rma.id
        }
      })
    },
    convertCentsToDollars: (cents) =>
      CurrencyHelper.convertCentsToDollars(cents),
    getStatusColor: (category) => {
      return RMAStatusHelper.getStatusColor(category)
    },
    getParentCategory: (status) => {
      return RMAStatusHelper.getParentCategory(status)
    },
    sanitizeStatus: (status) => {
      return RMAStatusHelper.sanitizeStatus(status)
    },
    searchRMAsForDashboard: function () {
      if (this.determineLookupEligibility()) {
        return
      }
      this.isLoading = true
      const params = this.buildQueryParams()

      RMAService.getRMAByField(params)
        .then((response) => {
          this.rawRMA = response.data
          this.rmaList()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    downloadAsCSV: function () {
      const params = this.buildQueryParams()

      RMAService.getRMAsAsCSV(params).then((response) => {
        DownloadHelper.downloadFile(
          new Blob([response.data]),
          `RMAs_${+new Date()}.csv`
        )
      })
    },
    buildQueryParams: function () {
      const value = this.isStatusCategorySelected() ? this.date : this.search
      return {
        category: this.selectedCategory.value,
        brand: this.selectedFilters.brand,
        return_type: this.selectedFilters.isGiftReturn,
        value
      }
    },
    updateSelectedSearchCategory: function (category) {
      this.selectedCategory = category
    },
    isStatusCategorySelected: function () {
      return (
        this.selectedCategory?.value &&
        this.selectedCategory?.value.includes('status')
      )
    },
    isNameCategorySelected: function () {
      return (
        this.selectedCategory?.value &&
        this.selectedCategory?.value.includes('name')
      )
    },
    determineLookupEligibility: function () {
      if (!this.isStatusCategorySelected() && !this.search.length) {
        return true
      }
      if (this.isNameCategorySelected() && this.search.length < 3) {
        return true
      }
    },
    determineCategoryLabel: function () {
      return `Search by ${
        this.isStatusCategorySelected() ? '' : this.selectedCategory.text
      }`
    },
    determineFieldSuffix: function () {
      return this.isStatusCategorySelected()
        ? ' Results will display up to 1000 RMAs since the date defined'
        : ''
    },
    getMonthDateFromToday: function () {
      const currentMonth = new Date().getMonth()
      const previousMonthFromToday = currentMonth === 0 ? 11 : currentMonth - 1
      const previousMonthFromTodayDate = new Date().setMonth(
        previousMonthFromToday
      )

      return new Date(
        previousMonthFromTodayDate - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10)
    },
    getBrandOptions: function () {
      RetailersService.getRetailers()
        .then((response) => {
          if (response.data) {
            this.brandOptions = this.formatBrandOptions(response.data)
          }
        })
    },
    formatBrandOptions: function (brandData) {
      return brandData.map(brand => ({ value: brand.optiturn_id, text: brand.name }))
    },
    updateBrandFilter: function (selectedBrand) {
      this.selectedFilters.brand = selectedBrand ? selectedBrand.value : ''
    },
    updateReturnTypeFilter: function (selectedReturnType) {
      this.selectedFilters.isGiftReturn = selectedReturnType ? selectedReturnType.value : false
    }
  }
}
</script>
<style>
.v-data-table-header th {
  white-space: nowrap;
}
.v-data-table tr {
  cursor: pointer;
}
.v-chip__content {
  color: white;
  font-weight: bold;
}
.search-container {
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
}
.v-text-field {
  padding-left: 0;
  margin: 0;
}
.date-label {
  color: grey;
}
.v-input {
  margin-bottom: 5px;
}
.filter{
  min-width: 145px;
}
.filter-small{
  min-width: 110px;
}
</style>
