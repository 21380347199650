<template>
  <div>
    <v-card outlined tile>
      <v-card-text class="card-text">
        <span class="label">Return subtotal</span><v-spacer /> {{ subTotal }}<br>
      </v-card-text>
    </v-card>
    <v-card outlined tile>
      <v-card-text class="card-text flex-column">
        <div class="d-flex mb-2">
          <span class="label">Exchange subtotal</span><v-spacer />
          {{ exchangeSubTotal }}
        </div>
        <div class="d-flex mb-2">
          <span class="label">Shipping fee</span><v-spacer />
          {{ returnShippingCost }}
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined tile>
      <v-card-text class="card-text total">
        <span class="label">Total estimated refund</span><v-spacer /> {{ totalRefundDue }}<br>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CurrencyHelper from '@/utils/currency'
export default {
  name: 'PricingInfo',
  props: {
    rma: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    convertCentsToDollars: (cents) => CurrencyHelper.convertCentsToDollars(cents)
  },
  computed: {
    subTotal () {
      return CurrencyHelper.convertCentsToDollars(this.rma?.refund_estimate?.subtotal_cents || 0)
    },
    exchangeSubTotal () {
      return CurrencyHelper.convertCentsToDollars(this.rma?.refund_estimate?.exchange_subtotal_cents || 0)
    },
    returnShippingCost () {
      return CurrencyHelper.convertCentsToDollars(this.rma?.refund_estimate?.return_shipping_cost_cents || 0)
    },
    totalRefundDue () {
      return CurrencyHelper.convertCentsToDollars(this.rma?.refund_estimate?.total_refund_cents || 0)
    }
  }
}
</script>
<style scoped>
.card-text {
  display: flex;
  color: black !important;
  font-weight: bold;
  font-size: 1.1rem;
}
.card-text .label {
  color: #9e9e9e !important;
  font-weight: normal;
}
.label {
  display: inline-block;
  min-width: 3.2rem;
}
.total {
  font-size: 1.3rem;
}
</style>
