<template>
  <v-app-bar
    flat
    outlined
    elevate-on-scroll
    app
  >
    <img
      :src="logoImage"
      alt="Optoro Logo"
      class="nav-logo"
    >

    <v-toolbar-title>Optiturn</v-toolbar-title>

    <v-spacer />
  </v-app-bar>
</template>

<script>
import logoImage from '@/assets/images/optoroLogo.png'

export default {
  data () {
    return {
      logoImage
    }
  }
}
</script>

<style scoped >
.navbar {
  width: 100%;
}

.navbar .container-fluid {
  background-color: #fff !important;
  box-shadow: 0px 0.5px 0px 0.5px #d8d8d8;
  height: 4.5rem;
}

.nav-logo {
  height: 100%;
  margin: 0.8em;
}
</style>
