<template>
  <v-card outlined color="#F6F6F6">
    <v-card-title class="">
      <v-avatar
        color="#FF9800"
        size="56"
        class="mr-3 mb-2 avatar"
      >
        {{ firstInitial }}{{ lastInitial }}
      </v-avatar>
      <div>
        <span class="profile-name"> {{ profileName }} </span><br>
        {{ email }}
      </div>
    </v-card-title>
    <v-card-text>
      Shipping Address
      <div class="shipping-address-text">
        <strong>{{ shippingName }}</strong><br>
        {{ shippingLine1 }}<br>
        {{ shippingLine2 }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'ProfileInfo',
  props: {
    rma: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    firstInitial () {
      return this.rma?.shopper?.first_name?.slice(0, 1)
    },
    lastInitial () {
      return this.rma?.shopper?.last_name?.slice(0, 1)
    },
    shippingName () {
      return this.rma?.shipping_address?.name || ''
    },
    shippingLine1 () {
      return `${this.rma?.shipping_address?.street1 || ''} ${this.rma?.shipping_address?.street2 || ''}`
    },
    shippingLine2 () {
      const address = this.rma?.shipping_address
      return address ? `${address.city}, ${address.state} ${address.zip_code} ${address.country_code}` : ''
    },
    profileName () {
      return `${this.rma?.shopper?.first_name || ''} ${this.rma?.shopper?.last_name || ''}`
    },
    email () {
      return this.rma?.shopper?.email || ''
    }
  }
}
</script>
<style scoped>
.profile-name {
  font-weight: bold;
  font-size: 1.5rem;
}
.shipping-address-text {
  color: black;
}
.avatar {
    color: white;
}
</style>
