import Vue from 'vue'

const DownloadHelper = {
  downloadFile: (blob, fileName) => {
    try {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      return true
    } catch (e) {
      Vue.$toast('Error downloading file', {
        timeout: 0,
        type: 'error',
        position: 'bottom-right'
      })
      return false
    }
  }
}

export default DownloadHelper
