var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showItems)?_c('div',_vm._l((_vm.items),function(item){return _c('div',{key:item.id},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{},[_vm._v(" "+_vm._s(item.order_item.title)+" ")]),_c('v-card-text',{staticClass:"card-text"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"image-wrapper",class:{
                'image-wrapper-large': _vm.$vuetify.breakpoint.mdAndUp,
              },style:({
                'background-image': `url(${item.order_item.image_url})`,
                width: '100%',
                height: '250px',
              })},[_c('v-img',{staticClass:"logo ml-2 mb-2",class:{
                  'logo-large': _vm.$vuetify.breakpoint.lg,
                },attrs:{"src":_vm.determineLogoPath(item) || _vm.determineLogoPath(_vm.rmaBrand),"max-width":'75px',"alt":"product-image","contain":""}})],1)]),_c('v-col',{staticClass:"item-details",attrs:{"cols":"12","lg":"5"}},[_c('div',[_c('span',{staticClass:"label"},[_vm._v("Order:")]),_c('br'),_vm._v(_vm._s(_vm.orderId))]),_c('div',[_c('span',{staticClass:"label"},[_vm._v("SKU:")]),_c('br'),_vm._v(_vm._s(item.sku))]),_c('div',[_c('span',{staticClass:"label"},[_vm._v("Reason:")]),_c('br'),(item.return_reason && item.return_reason.name)?_c('span',[_vm._v(" "+_vm._s(item.return_reason.name)+" ")]):(
                  item.return_reason &&
                    item.return_reason.return_reason_feedback
                )?_c('span',[_vm._v(" "+_vm._s(item.return_reason.return_reason_feedback)+" ")]):_vm._e()]),(
                item.order_item.variants &&
                  item.order_item.variants.length > 0
              )?_c('div',_vm._l((item.order_item.variants[0].attributes),function(attribute){return _c('div',{key:attribute.name},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(attribute.name)+":")]),_c('br'),_vm._v(_vm._s(attribute.value)+" ")])}),0):_vm._e(),_c('div',[_c('span',{staticClass:"label"},[_vm._v("Shipped:")]),_c('br'),_vm._v(_vm._s(_vm.getShippedDate(item))+" ")]),_c('div',[_c('span',{staticClass:"label"},[_vm._v("Status:")]),_c('br'),_vm._v(_vm._s(_vm.getStatus(item))+" ")])]),_c('v-col',{staticClass:"item-price",attrs:{"cols":"12","lg":"3"}},[_vm._v(" "+_vm._s(_vm.convertCentsToDollars( item.order_item.calculated_product_amount_cents ))+" ")])],1)],1)],1)],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }