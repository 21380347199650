import { apiClient } from '@/services/api.service'

const RMAService = {
  getRMA: () => apiClient().get('/v1/admin/rma/'),
  getRMAById: (id) => apiClient().get(`/v1/admin/rma/${id}`),
  getRMAByField: (params) => apiClient().get('/v1/admin/rma', { params }),
  updateRMAById: (id, data) => apiClient().patch(`/v1/admin/rma/${id}`, data),
  getRMAsAsCSV: (params) => {
    const csvOptions = {
      headers: {
        Accept: 'text/csv'
      },
      responseType: 'blob',
      params
    }

    return apiClient().get('/v1/admin/rma/', csvOptions)
  },
  republishRMAById: (id) =>
    apiClient().post('/v1/admin/rma/republish', { id })
}

export default RMAService
