<template>
  <v-container class="side-nav">
    <v-navigation-drawer
      permanent
      app
      dark
      expand-on-hover
      class="drawer"
    >
      <v-divider />

      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <router-link class="router-link d-flex" :to="item.path">
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import { CONSTANTS } from '@/constants'

export default {
  name: CONSTANTS.COMPONENTS.SIDE_NAV.NAME,
  data () {
    return {
      items: [
        {
          title: 'RMA Management',
          icon: 'mdi-truck-delivery',
          path: CONSTANTS.COMPONENTS.DASHBOARD.PATH
        },
        {
          title: 'Order Lookup',
          icon: 'mdi-credit-card-search',
          path: CONSTANTS.COMPONENTS.ORDERS.PATH
        },
        {
          title: 'Logout',
          icon: 'mdi-logout',
          path: CONSTANTS.COMPONENTS.LOGOUT.PATH
        }
      ],
      right: null
    }
  }
}
</script>

<style scoped >
.drawer {
  background-color: #2196f3 !important;
}
.title {
  height: 4rem;
  margin: 0;
}
.link {
    text-decoration: none;
}
.router-link {
    color: #eeeeee;
    text-decoration: none;
    transition: all 0.2s;
}
.router-link > .v-list-item__icon > .v-icon {
    color: #eeeeee;
}

.router-link-active {
    color: white!important;
    font-weight: bold;
    margin-left: 1rem;
}
.router-link-active > .v-list-item__icon > .v-icon {
    color: white;
}

</style>
