<template>
  <v-app id="app">
    <span id="build-version" v-if="showBuildVersion">
      Build Version: {{ this.buildVersion }}
    </span>
    <Header />
    <v-main>
      <SideNav />
      <keep-alive exclude="RMA_DETAIL">
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/layouts/Header'
import SideNav from '@/components/layouts/SideNav'
const { defaultTo } = require('lodash')

export default {
  components: {
    Header,
    SideNav
  },
  data () {
    return {
      buildVersion: defaultTo(process.env.VUE_APP_BUILD_VERSION, 'local'),
      appEnvironment: defaultTo(process.env.VUE_APP_ENVIRONMENT, 'development')
    }
  },
  computed: {
    showBuildVersion () {
      return this.appEnvironment === 'staging'
    }
  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Retailer Portal'
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
  #app {
    font-family: 'avenir', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body, h1, h2, h3, h4, h5, h6, p, span {
    /* overflow-y: auto !important; */
    font-family: 'avenir', sans-serif !important;
  }
  #build-version {
    position: fixed;
    top: 0;
    right: 0;
    padding: 5px;
    z-index: 99;
  }
</style>
