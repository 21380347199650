<template>
  <v-container class="item-detail" app>
    <v-row class="detail-header" no-gutters>
      <v-col
        class="text-left"
        :class="{ 'text-center': $vuetify.breakpoint.xs }"
        cols="12"
        lg="4"
      >
        <router-link to="/dashboard" exact>
          <v-btn color="blue-grey" class="ma-2 white--text">
            <v-icon left dark>
              mdi-arrow-left
            </v-icon>
            Back to results
          </v-btn>
        </router-link>
      </v-col>
      <v-col class="text-center d-flex align-center justify-center" cols="12">
        <span
          class="detail-title"
        >RMA <strong>{{ rmaIdentifier }}</strong></span>
        <v-spacer />
        <v-btn
          :color="statusColor"
          rounded
          class="justify-center white--text"
        >
          {{ status }}
        </v-btn>
      </v-col>
      <v-col
        class="text-right"
        cols="12"
        lg="4"
      />
    </v-row>
    <v-container id="app" app>
      <v-row>
        <v-col cols="12" lg="3">
          <profile-info :rma="rmaDetail" />
          <return-info
            :rma="rmaDetail"
            :loading="isLoading"
            @create-shipping-label="createShippingLabel"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <v-tabs>
            <v-tab @click="() => setActiveTab('ITEMS')">
              <v-icon class="mr-2">
                mdi-shopping
              </v-icon> Items
            </v-tab>
            <v-tab @click="() => setActiveTab('NOTES')">
              <v-icon class="mr-2">
                mdi-text
              </v-icon> Notes
            </v-tab>
            <!-- <v-tab>
              <v-icon class="mr-2">
                mdi-code-tags
              </v-icon> Log
            </v-tab> -->
          </v-tabs>
          <order-items
            v-if="activeTab === 'ITEMS'"
            :items="rmaItems"
            :order-id="orderId"
            :rma-brand="this.rmaDetail && this.rmaDetail.concept"
            :rma-keep="this.rmaDetail && this.rmaDetail.customer_keep"
          />
          <notes v-if="activeTab === 'NOTES'" :rma="rmaDetail" />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          class="pricing-section"
        >
          <pricing-info :rma="rmaDetail" />
          <div class="action-button-container">
            <v-btn
              color="secondary"
              class="action-button"
              block
              elevation="5"
              :loading="isLoading"
              :disabled="!isRepublishAllowed || isLoading"
              v-if="canRefund && allowManualRefunds && hasRepublishRefundRights"
              @click="handleRepublishRMA()"
            >
              Republish
            </v-btn>
          </div>
          <div class="action-button-container">
            <v-btn
              color="primary"
              class="action-button"
              x-large
              block
              elevation="5"
              :loading="isLoading"
              :disabled="!isActionAllowed || isLoading || isGiftReturn"
              v-if="canRefund && allowManualRefunds"
              @click="updateRMAStatus('READY_TO_REFUND')"
            >
              Ready to Refund
            </v-btn>
            <v-btn
              color="primary"
              class="action-button no-padding"
              large
              text
              block
              v-if="isGiftReturn && allowManualRefunds"
              :loading="isLoading"
              :disabled="!isActionAllowed || isLoading"
              @click="updateRMAStatus('REFUNDED')"
            >
              Refund Outside of Portal
            </v-btn>
            <v-btn
              color="error"
              class="action-button"
              x-large
              block
              elevation="0"
              :loading="isLoading"
              :disabled="!isActionAllowed || isLoading"
              v-if="canCancel && !hasExchange"
              @click="updateRMAStatus('CANCELED')"
            >
              Cancel
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { CONSTANTS } from '@/constants'
import ProfileInfo from '../common/ProfileInfo'
import ReturnInfo from '../common/ReturnInfo'
import OrderItems from '../common/OrderItems'
import PricingInfo from '../common/PricingInfo'
import Notes from '../common/Notes'
import RMAService from '@/services/rma.service'
import RMAStatusHelper from '@/utils/rma-status'
import ShippingService from '@/services/shipping.service'
import Auth from '@/utils/auth'

export default {
  name: CONSTANTS.COMPONENTS.RMA_DETAIL.NAME,
  components: {
    ProfileInfo,
    ReturnInfo,
    OrderItems,
    PricingInfo,
    Notes
  },
  data () {
    return {
      isLoading: true,
      rmaDetail: undefined,
      activeTab: 'ITEMS',
      status: '',
      statusColor: 'default'
    }
  },
  mounted () {
    document.title = `${this.$route.meta.title}${this.rmaId}`
    setTimeout(() => {
      this.setRMADetail()
    }, 1000)
  },
  computed: {
    // db reference
    rmaId () {
      return this.$route.params.id
    },
    isGiftReturn () {
      return this.rmaDetail?.is_gift
    },
    // used by CSR
    rmaIdentifier () {
      return this.rmaDetail?.identifier || ''
    },
    returnShippingCostCents () {
      return this.rmaDetail?.refund_financials?.return_shipping_cost_cents
    },
    trackingNumber () {
      return this.rmaDetail?.rma_items[0]?.shipping_label_details
        ?.tracking_number
    },
    returnMethod () {
      return this.rmaDetail?.rma_items[0]?.return_method?.name
    },
    rmaItems () {
      return this.rmaDetail?.rma_items
    },
    orderId () {
      return this.rmaDetail?.order_id || '0'
    },
    isActionAllowed () {
      return RMAStatusHelper.isActionAllowedForStatus(
        this.rmaDetail?.status || ''
      )
    },
    allowManualRefunds () {
      return this.rmaDetail?.allow_manual_refunds
    },
    canRefund () {
      return Auth.has(CONSTANTS.RIGHTS.MANUALLY_REFUND_RX_RMA)
    },
    canCancel () {
      return Auth.has(CONSTANTS.RIGHTS.CANCEL_RX_RMA)
    },
    hasRepublishRefundRights () {
      return Auth.has(CONSTANTS.RIGHTS.OPTORO_RX_ADMIN)
    },
    isRepublishAllowed () {
      return RMAStatusHelper.isRepublishAllowedForStatus(
        this.rmaDetail?.status || ''
      )
    },
    hasExchange () {
      const items = this.rmaDetail?.rma_items || []

      return items.some(item => item.is_exchange)
    }
  },
  methods: {
    setRMADetail () {
      this.isLoading = true
      RMAService.getRMAById(this.rmaId)
        .then((response) => {
          this.$storage.set(CONSTANTS.STORAGE.KEYS.RMA_DETAIL, response.data)
          this.rmaDetail = response.data
          if (isNaN(this.rmaId)) {
            document.title = `${this.$route.meta.title}${this.rmaIdentifier}`
          }
          this.status = RMAStatusHelper.sanitizeStatus(this.rmaDetail.status)
          this.statusColor = RMAStatusHelper.getStatusColor(
            RMAStatusHelper.getParentCategory(this.rmaDetail.status)
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async handleRepublishRMA () {
      this.isLoading = true
      await RMAService.republishRMAById(this.rmaId)
      this.isLoading = false
    },
    async updateRMAStatus (status) {
      this.isLoading = true
      await RMAService.updateRMAById(this.rmaId, { id: this.rmaId, status })
      this.isLoading = false
      this.setRMADetail()
    },
    async createShippingLabel () {
      this.isLoading = true
      const shippingResponse = await ShippingService.createShippingLabel(
        this.rmaIdentifier
      )
      if (shippingResponse && shippingResponse.status === 200) {
        this.$toast(CONSTANTS.UI_MESSAGES.SHIPPING_LABEL_CREATED, {
          timeout: 5000,
          type: 'success',
          position: 'bottom-right'
        })
      } else {
        this.$toast.clear() // clear generic error notification
        this.$toast(CONSTANTS.UI_MESSAGES.SHIPPING_LABEL_ERROR, {
          timeout: 5000,
          type: 'error',
          position: 'bottom-right'
        })
      }
      this.isLoading = false
    },
    setActiveTab (tab) {
      this.activeTab = tab
    }
  }
}
</script>
<style scoped>
.no-padding {
  padding: 0 !important;
}
.detail-header {
  display: flex;
  padding: 1.5rem;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #e1e1e1;
}
.detail-title {
  font-size: 2rem;
}
.v-toolbar__content {
  align-items: center !important;
}
.pricing-section {
  margin-top: 48px;
}
.action-button-container {
  text-transform: uppercase;
}
.action-button {
  margin-top: 1.5rem;
  font-weight: bold;
}
</style>
