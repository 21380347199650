const DecodeJwt = {
  decode: (token) => {
    const payload = atob(token.split('.')[1])
    return JSON.parse(payload)
  },
  extractRights: (token) => {
    return DecodeJwt.decode(token)?.user_rights || []
  }
}

export default DecodeJwt
export const extractRights = DecodeJwt.extractRights
