<template>
  <v-card outlined>
    <v-card-title class="">
      Return Information
    </v-card-title>
    <v-card-text class="card-text" v-if="this.rma">
      <span class="label">Gift Return:</span> {{ bakedRMA.isGiftFriendlyValue
      }}<br>
      <span class="label">Gift Return Email:</span> {{ bakedRMA.giftReturnEmail
      }}<br>
      <span class="label">Requested:</span> {{ bakedRMA.createdAt }}<br>
      <span class="label">Last Updated:</span> {{ bakedRMA.updatedAt }}<br>
      <br>
      <span class="label">Method:</span> {{ bakedRMA.shippingMethod }}<br>

      <span class="label">Label Cost:</span> {{ bakedRMA.labelCost }}<br>
      <span class="label">Exchange:</span> {{ bakedRMA.isExchange }}<br>
      <span class="label" v-if=" bakedRMA.exchangeOrderIdentifier !== null">Exchange Order ID:</span> {{ bakedRMA.exchangeOrderIdentifier !== null ? bakedRMA.exchangeOrderIdentifier : '' }}<br>
      <span class="label" v-if="bakedRMA.trackingNumber.length">Tracking Number:</span><br>
      <a
        :href="trackingLink"
        target="_blank"
        v-if="trackingLink.length"
      >{{ bakedRMA.trackingNumber
      }}</a>
      <span v-if="!trackingLink.length" class="label">{{ bakedRMA.trackingNumber }}</span>
      <br>
      <div class="action-button-container">
        <v-btn
          color="error"
          class="action-button"
          block
          outlined
          elevation="0"
          :loading="loading"
          v-if="!bakedRMA.trackingNumber.length && canCreateShipppingLabel"
          :disabled="loading"
          @click="createShippingLabel()"
        >
          Create Shipping Label
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyHelper from '@/utils/currency'
import Auth from '@/utils/auth'
import { CONSTANTS } from '@/constants'

export default {
  name: 'ReturnInfo',
  data () {
    return {
      trackingLink: ''
    }
  },
  props: {
    rma: {
      type: Object,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canCreateShipppingLabel () {
      return Auth.has(CONSTANTS.RIGHTS.OPTORO_RX_ADMIN)
    },
    bakedRMA () {
      if (!this.rma) {
        return {
          isGiftFriendlyValue: 'No',
          giftReturnEmail: '',
          createdAt: new Date().toLocaleString(),
          updatedAt: new Date().toLocaleString(),
          shippingMethod: '',
          trackingNumber: '',
          labelCost: CurrencyHelper.convertCentsToDollars(0),
          isExchange: false,
          exchangeOrderIdentifier: ''
        }
      } else {
        const isExchange = this.rma?.rma_items?.findIndex(item => item.is_exchange === true) !== -1
        const shippingMethod = this.rma?.customer_keep ? 'Keep' : this.rma?.rma_items[0]?.return_method?.name || ''
        const trackingNumber = this.rma?.rma_items[0]?.shipping_label_details?.tracking_number ||
            ''
        this.setShippingLinkByMethod(shippingMethod, trackingNumber)
        return {
          isGiftFriendlyValue: this.rma?.is_gift ? 'Yes' : 'No',
          giftReturnEmail: this.rma?.is_gift ? this.rma?.shopper?.giftee_email : '',
          createdAt: new Date(this.rma?.created_at).toLocaleString(),
          updatedAt: new Date(this.rma?.updated_at).toLocaleString(),
          shippingMethod: shippingMethod,
          trackingNumber: trackingNumber,
          labelCost: CurrencyHelper.convertCentsToDollars(this.rma?.return_shipping_cost_cents),
          isExchange: isExchange ? 'Yes' : 'No',
          exchangeOrderIdentifier: isExchange ? this.rma?.rma_items[0]?.exchange_order_identifier : null
        }
      }
    }
  },
  methods: {
    convertCentsToDollars: (cents) => CurrencyHelper.convertCentsToDollars(cents),
    setShippingLinkByMethod: function (method, trackingNumber) {
      let link = ''
      switch (method.toUpperCase()) {
        case CONSTANTS.SHIPPING_METHODS.TYPES.UPS:
          link = CONSTANTS.SHIPPING_METHODS.LINKS.UPS
          break
        case CONSTANTS.SHIPPING_METHODS.TYPES.FEDEX:
          link = CONSTANTS.SHIPPING_METHODS.LINKS.FEDEX
          break
        default:
          return
      }
      this.trackingLink = `${link}${trackingNumber}`
    },
    createShippingLabel () {
      this.$emit('create-shipping-label')
    }
  }
}
</script>
<style scoped>
.card-text {
  color: black !important;
  font-weight: bold;
}
.card-text .label {
  color: #9e9e9e !important;
  font-weight: normal;
}
.subtitle {
  color: "#696969";
}
</style>
