import { CONSTANTS } from '@/constants'
import { extractRights } from '@/utils/decode-jwt'
import Vue from 'vue'

const Auth = {
  storeNewToken: (newToken) => {
    Vue.storage.set(CONSTANTS.STORAGE.KEYS.AUTH_TOKEN, newToken)

    Vue.storage.remove(CONSTANTS.STORAGE.KEYS.AUTH_RIGHTS)
    let rights = []
    try {
      rights = extractRights(newToken)
    } catch {
      // ignore any parsing errors, we check for right below
    }
    if (rights.includes(CONSTANTS.RIGHTS.USE_RX_RMA_ADMIN)) {
      Vue.storage.set(CONSTANTS.STORAGE.KEYS.AUTH_RIGHTS, rights)
    }
  },
  isAuthenticated: () => {
    const token = Vue.storage.get(CONSTANTS.STORAGE.KEYS.AUTH_TOKEN)

    return token && token.length >= 0 && token !== null
  },
  isAuthorized: () => {
    return Auth.has(CONSTANTS.RIGHTS.USE_RX_RMA_ADMIN)
  },
  logout: () => {
    Vue.storage.remove(CONSTANTS.STORAGE.KEYS.AUTH_TOKEN)
  },
  has: (right) => {
    const rights = Vue.storage.get(CONSTANTS.STORAGE.KEYS.AUTH_RIGHTS)

    return rights && rights.includes(right)
  },
  storeReturnPath: (url) => {
    Vue.storage.set(CONSTANTS.STORAGE.KEYS.RETURN_PATH, url)
  },
  getReturnPath: () => {
    return Vue.storage.get(CONSTANTS.STORAGE.KEYS.RETURN_PATH) || '/'
  },
  clearReturnPath: () => {
    Vue.storage.remove(CONSTANTS.STORAGE.KEYS.RETURN_PATH)
  }

}

export default Auth
export const storeNewToken = Auth.storeNewToken
export const isAuthenticated = Auth.isAuthenticated
export const isAuthorized = Auth.isAuthorized
export const logout = Auth.logout
export const has = Auth.has
export const storeReturnPath = Auth.storeReturnPath
export const getReturnPath = Auth.getReturnPath
export const clearReturnPath = Auth.clearReturnPath
