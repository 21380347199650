export const CONSTANTS = Object.freeze({
  COMPONENTS: {
    LOGIN: {
      NAME: 'LOGIN',
      PATH: '/login'
    },
    LOGOUT: {
      NAME: 'LOGOUT',
      PATH: '/logout'
    },
    DASHBOARD: {
      NAME: 'DASHBOARD',
      PATH: '/dashboard'
    },
    RMA_DETAIL: {
      NAME: 'RMA_DETAIL',
      PATH: '/rma/:id'
    },
    ORDERS: {
      NAME: 'ORDERS',
      PATH: '/orders'
    },
    SIDE_NAV: {
      NAME: 'SIDE_NAV'
    }
  },
  PILLS: {
    COLORS: {
      RED: 'red',
      ORANGE: 'orange',
      YELLOLW: 'yellow',
      GREEN: 'green',
      BLUE: 'blue',
      PURPLE: 'purple'
    }
  },
  RMA_STATUS: {
    CREATED: 'CREATED',
    GOODS_TRANSIT: 'GOODS_TRANSIT',
    READY_TO_REFUND: 'READY_TO_REFUND',
    REFUNDED: 'REFUNDED',
    PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
    CANCELED: 'CANCELED',
    REFUND_ERROR: 'REFUND_ERROR',
    AUTOCLOSED: 'AUTOCLOSED'
  },
  FILTER_STATES: {
    ALL: 'All',
    PENDING: 'Pending',
    NEEDS_REVIEW: 'Needs Review',
    COMPLETED: 'Completed'
  },
  STORAGE: {
    KEYS: {
      RMA_DETAIL: 'RMA_DETAIL',
      AUTH_TOKEN: 'AUTH_TOKEN',
      AUTH_RIGHTS: 'AUTH_RIGHTS',
      RETURN_PATH: '/'
    }
  },
  SHIPPING_METHODS: {
    TYPES: {
      UPS: 'UPS',
      FEDEX: 'FEDEX'
    },
    LINKS: {
      UPS: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
      FEDEX: 'https://www.fedex.com/fedextrack/system-error?trknbr='
    }
  },
  RIGHTS: {
    USE_RX_RMA_ADMIN: 'use_rx_rma_admin',
    CREATE_RX_RMA: 'create_rx_rma',
    CANCEL_RX_RMA: 'cancel_rx_rma',
    MANUALLY_REFUND_RX_RMA: 'manually_refund_rx_rma',
    OVERRIDE_RX_RMA_STATUS: 'override_rx_rma_status',
    OPTORO_RX_ADMIN: 'optoro_rx_admin'
  },
  UI_MESSAGES: {
    SHIPPING_LABEL_CREATED: 'The shipping label has been created and sent to the customer.',
    SHIPPING_LABEL_ERROR: 'Failed to create the shipping label.'
  }

})
