import Vue from 'vue'
import axios from 'axios'
import { CONSTANTS } from '@/constants'

function apiClient () {
  const ax = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
      common: {
        Authorization: `Bearer ${Vue.storage.get(CONSTANTS.STORAGE.KEYS.AUTH_TOKEN)}`
      }
    }
  })

  ax.interceptors.response.use((response) => response, (error) => {
    if (error.response) {
      Vue.$toast(`NETWORK ERROR: ${JSON.stringify(error.response.status)}`, {
        timeout: 0,
        type: 'error',
        position: 'bottom-right'
      })

      if (error.response.status === 401 || error.response.status === 403) {
        // unauthorized, token is probably no good anymore, drop it
        Vue.storage.remove(CONSTANTS.STORAGE.KEYS.AUTH_TOKEN)
      }
    }
  })

  return ax
}

export { apiClient }
