import { CONSTANTS } from '@/constants'

const RMAStatusHelper = {
  getStatusColor: (category) => {
    switch (category) {
      case CONSTANTS.FILTER_STATES.COMPLETED:
        return CONSTANTS.PILLS.COLORS.GREEN
      case CONSTANTS.FILTER_STATES.NEEDS_REVIEW:
        return CONSTANTS.PILLS.COLORS.RED
      default:
        return CONSTANTS.PILLS.COLORS.BLUE
    }
  },
  getParentCategory: (status) => {
    if (!status) {
      return ''
    }
    if (
      [
        CONSTANTS.RMA_STATUS.CREATED,
        CONSTANTS.RMA_STATUS.READY_TO_REFUND
      ].indexOf(status.toUpperCase()) > -1
    ) {
      return CONSTANTS.FILTER_STATES.PENDING
    } else if (
      [
        CONSTANTS.RMA_STATUS.REFUNDED,
        CONSTANTS.RMA_STATUS.PARTIAL_REFUNDED,
        CONSTANTS.RMA_STATUS.CANCELED
      ].indexOf(status.toUpperCase()) > -1
    ) {
      return CONSTANTS.FILTER_STATES.COMPLETED
    } else if (
      [
        CONSTANTS.RMA_STATUS.REFUND_ERROR,
        CONSTANTS.RMA_STATUS.AUTOCLOSED
      ].indexOf(status.toUpperCase()) > -1
    ) {
      return CONSTANTS.FILTER_STATES.NEEDS_REVIEW
    }
    return ''
  },
  sanitizeStatus: (status) => {
    return status?.toUpperCase().replace(/_/g, ' ') || ''
  },
  isActionAllowedForStatus: (status) => {
    if (!status) {
      return false
    }

    const actionAllowedForStatus = [CONSTANTS.RMA_STATUS.CREATED, CONSTANTS.RMA_STATUS.REFUND_ERROR]
    // return TRUE if status is whitelisted to take action
    return actionAllowedForStatus.indexOf(status.toUpperCase()) > -1
  },
  isRepublishAllowedForStatus: (status) => {
    if (!status) {
      return false
    }

    const actionAllowedForStatus = [CONSTANTS.RMA_STATUS.CREATED, CONSTANTS.RMA_STATUS.READY_TO_REFUND]
    return actionAllowedForStatus.indexOf(status.toUpperCase()) > -1
  }
}

export default RMAStatusHelper
