import Vue from 'vue'
import Router from 'vue-router'
import { CONSTANTS } from '../constants'
import Login from '@/components/pages/Login.vue'
import Logout from '@/components/pages/Logout.vue'
import Dashboard from '@/components/pages/Dashboard.vue'
import Orders from '@/components/pages/Orders.vue'
import ItemDetail from '@/components/pages/ItemDetail.vue'
import { isAuthorized, isAuthenticated } from '@/utils/auth'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: CONSTANTS.COMPONENTS.DASHBOARD.PATH,
      name: CONSTANTS.COMPONENTS.DASHBOARD.NAME,
      component: Dashboard,
      meta: {
        title: 'Retailer Portal | Dashboard'
      }
    },
    {
      path: CONSTANTS.COMPONENTS.RMA_DETAIL.PATH,
      name: CONSTANTS.COMPONENTS.RMA_DETAIL.NAME,
      component: ItemDetail,
      meta: {
        title: 'Retailer Portal | RMA #'
      }
    },
    {
      path: CONSTANTS.COMPONENTS.LOGIN.PATH,
      name: CONSTANTS.COMPONENTS.LOGIN.NAME,
      component: Login,
      meta: {
        title: 'Retailer Portal | Login'
      }
    },
    {
      path: CONSTANTS.COMPONENTS.LOGOUT.PATH,
      name: CONSTANTS.COMPONENTS.LOGOUT.NAME,
      component: Logout,
      meta: {
        title: 'Retailer Portal | Logout'
      }
    },
    {
      path: CONSTANTS.COMPONENTS.ORDERS.PATH,
      name: CONSTANTS.COMPONENTS.ORDERS.NAME,
      component: Orders,
      meta: {
        title: 'Retailer Portal | Orders'
      }
    },
    {
      path: '*',
      redirect: CONSTANTS.COMPONENTS.DASHBOARD.PATH
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    const returnPath = to.query.returnPath || to.fullPath

    console.log('1', to)
    if (!isAuthenticated() || !isAuthorized()) {
      next({ path: '/login', query: { returnPath } }) // Redirect to /login if not authenticated/authorized
    }
  }
  next()
})

export default router
