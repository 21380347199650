import { apiClient } from '@/services/api.service'

const options = {
  headers: {
    Accept: 'application/json'
  }
}

const NoteService = {
  getNotesByRMA: (id) => apiClient().get(`/v1/admin/notes?rma_id=${id}`, options),
  createNote: (data) => apiClient().post('/v1/admin/notes/', data)
}

export default NoteService
