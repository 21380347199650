const CurrencyHelper = {
  convertCentsToDollars: (cents) => {
    if (!cents) {
      return (0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
    return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
}

export default CurrencyHelper
