<template>
  <v-main class="logout" app>
    <v-container id="app" app>
      <v-row>
        <v-col>
          <h1 class="heading">
            Redirecting to Login ...
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { CONSTANTS } from '@/constants'
import { logout } from '@/utils/auth'
const { defaultTo } = require('lodash')

export default {
  name: CONSTANTS.COMPONENTS.LOGOUT.NAME,
  mounted: function () {
    this.$router.replace(('/'))
    logout()
    const logoutUrl = defaultTo(process.env.VUE_APP_LOGOUT_URL, 'https://optiturn.com/logout')
    window.location.href = logoutUrl
  }
}
</script>
