<template>
  <v-main class="login" app>
    <v-container id="app" app>
      <v-row>
        <v-col>
          <h1 class="heading">
            Redirecting to Login ...
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { CONSTANTS } from '@/constants'
import { storeNewToken, isAuthenticated, isAuthorized, clearReturnPath, storeReturnPath, getReturnPath } from '@/utils/auth'
const { defaultTo } = require('lodash')

export default {
  name: CONSTANTS.COMPONENTS.LOGIN.NAME,
  mounted: function () {
    // REDIRECT BACK FROM OPTITURN LOGIN => store new token
    const newToken = this.$router.currentRoute.query.token
    if (newToken && newToken.length > 0 && this.$router.currentRoute.query.token !== null) {
      storeNewToken(newToken)
    }
    if (this.$router.currentRoute.query.returnPath) {
      storeReturnPath(this.$router.currentRoute.query.returnPath)
    }
    // SEE IF WE HAVE A TOKEN & SUFFICIENT RIGHTS
    if (isAuthenticated()) {
      if (isAuthorized()) {
        // SEEMS WE ARE AUTHed => carry on
        const returnPath = getReturnPath()
        if (returnPath) {
          this.$router.push({ path: returnPath })
          clearReturnPath()
        } else {
          this.$router.push('/')
        }
      } else {
        // redirect back to OT without returnUrl if user has insufficient rights to use RX RMA Admin
        const loginUrl = defaultTo(process.env.VUE_APP_LOGIN_URL, 'https://optiturn.com/session/new')
        window.location.href = `${loginUrl}`
      }
    } else {
      // REDIRECT TO OPTITURN LOGIN => with ?returnUrl param pointing back to us
      const loginUrl = defaultTo(process.env.VUE_APP_LOGIN_URL, 'https://optiturn.com/session/new')
      const returnUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}`)
      window.location.href = `${loginUrl}?returnUrl=${returnUrl}&service=rx_rma_admin`
    }
  }
}
</script>
