<template>
  <v-container id="app">
    <v-row>
      <v-col>
        <h1 class="heading">
          Order Lookup
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container class="search-container pa-8 pb-6" color="#F0F0F0">
          <v-row class="align-center justify-space-between">
            <v-col cols="3" class="pr-0">
              <search-select
                :items="searchCategories"
                :default-value="searchCategories[0]"
                @change="updateSelectedSearchCategory"
              />
            </v-col>
            <v-col
              lg="6"
              md="4"
              sm="4"
              class="pl-0"
            >
              <v-text-field
                v-model="search"
                :label="'Search by ' + selectedCategory.text || ''"
                @keyup.enter="doSearch"
                hide-details
                class="pt-0"
              >
                <template #prepend-inner>
                  <v-icon class="mr-2">
                    mdi-magnify
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="flex-grow-0">
              <v-btn
                @click="doSearch()"
                :disabled="!search.length"
              >
                <v-icon class="mr-2">
                  mdi-magnify
                </v-icon>
                Lookup Order
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="orders"
          :items-per-page="10"
          :no-data-text="'Please search by Order ID or Customer Email.'"
          :no-results-text="'No orders found for that search.'"
          :loading="isLoading"
          :loading-text="'Fetching orders...'"
          class="row-pointer elevation-1"
        >
          <template #[`item.order.created_at`]="{ item }">
            <span>{{ new Date(item.order.created_at).toLocaleString() }}</span>
          </template>
          <template #[`item.actions`]="{ item }">
            <div v-if="item.all_items_returned">
              All items returned
            </div>
            <div v-else-if="canOverride">
              <v-btn
                @click="override(item.order.identifier)"
                v-if="!item.order.csr_override"
                color="error"
              >
                Override
              </v-btn>
              <div v-else>
                Override successful
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONSTANTS } from '@/constants'
import OrderService from '@/services/order.service'
import Auth from '@/utils/auth'
import SearchSelect from '@/components/common/SearchSelect.vue'

export default {
  name: CONSTANTS.COMPONENTS.ORDERS.NAME,
  components: {
    SearchSelect
  },
  data () {
    return {
      orders: [],
      isLoading: false,
      search: '',
      selectedCategory: {},
      searchCategories: [
        { value: 'first_name', text: 'First name' },
        { value: 'last_name', text: 'Last name' },
        { value: 'shopper_email', text: 'Email' },
        { value: 'order_id', text: 'Order ID' }
      ],
      headers: [
        { value: 'order_id', text: 'Order ID' },
        { value: 'order.customer.first_name', text: 'Customer First Name' },
        { value: 'order.customer.last_name', text: 'Customer Last Name' },
        { value: 'shopper_email', text: 'Customer Email' },
        { value: 'zipcode', text: 'Zip Code' },
        { value: 'order.created_at', text: 'Created' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  mounted () {
    this.selectedCategory = this.searchCategories[0]
  },
  computed: {
    canOverride () {
      return Auth.has(CONSTANTS.RIGHTS.OVERRIDE_RX_RMA_STATUS)
    }
  },
  methods: {
    doSearch: function () {
      if (!this.search) {
        return
      }
      this.isLoading = true
      const params = {
        category: this.selectedCategory.value,
        value: this.search
      }

      OrderService.getOrdersByQuery(params)
        .then((response) => {
          this.orders = response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    override: function (identifier) {
      this.isLoading = true

      OrderService.overrideOrderByCSR(identifier)
        .then(() => {
          this.$toast('Order overriden successfully.', {
            timeout: 5000,
            type: 'success',
            position: 'bottom-right'
          })
          this.doSearch()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateSelectedSearchCategory: function (category) {
      this.selectedCategory = category
    }
  }
}
</script>
<style scoped>
.search-container {
  padding: 1rem;
  background-color: #f0f0f0;
}
</style>
