<template>
  <v-select
    v-model="select"
    :items="this.items"
    hide-details
    return-object
    outlined
    dense
    @change="onChange"
    v-bind="{ ...$props, ...$attrs }"
  />
</template>

<script>
export default {
  name: 'SearchSelect',
  data () {
    return {
      select: this.defaultValue || null
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onChange (a) {
      this.$emit('change', a)
    }
  },
  computed: {}
}
</script>
