<template>
  <div>
    <v-card
      tile
      elevation="2"
    >
      <v-card-text class="card-text">
        <v-form
          ref="form"
          @submit.prevent="createNote()"
          v-model="validNote"
        >
          <v-text-field
            v-model="newNote"
            :rules="newNoteRules"
            :counter="300"
            label="Add a new note"
            required
            class="mb-4"
          />
          <v-btn
            class="mr-4"
            type="submit"
            :disabled="!validNote"
          >
            Add note
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <div
      class="mt-4"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        class="mb-5"
        v-if="isLoading"
      />
      <div
        v-if="!notes.length"
      >
        <strong>No notes to display.</strong>
      </div>
      <v-container>
        <v-card
          outlined
          v-for="note in notes"
          :key="note.id"
          tile
        >
          <v-card-title class="">
            {{ note.note }}
          </v-card-title>
          <v-card-text class="grey--text">
            {{ new Date(note.created_at).toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import NoteService from '@/services/note.service'

export default {
  name: 'Notes',
  props: {
    rma: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      notes: [],
      validNote: false,
      newNote: '',
      newNoteRules: [
        v => !!v || 'Please enter some content',
        v => (v?.length || 0) <= 300 || 'Note must be less than 300 characters'
      ],
      isLoading: true
    }
  },
  mounted () {
    this.getNotesByRMA()
  },
  methods: {
    async getNotesByRMA () {
      this.isLoading = true
      if (this.rma) {
        const response = await NoteService.getNotesByRMA(this.rma.id)
        this.notes = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
      this.isLoading = false
    },
    async createNote () {
      this.isLoading = true
      if (this.rma) {
        const body = {
          rma_id: this.rma.id,
          note: this.newNote
        }
        const note = await NoteService.createNote(body)
        if (note.status === 200) {
          this.$toast('Note saved.', {
            timeout: 5000,
            type: 'success',
            position: 'bottom-right'
          })
        }
        await this.getNotesByRMA()
        this.$refs.form.reset()
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.card-text {
  color: black !important;
  font-weight: bold;
}
.card-text .label {
  color: #9e9e9e !important;
  font-weight: normal;
}
.label {
  display: inline-block;
  min-width: 3.2rem;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.item-details > div {
  margin: 0 0 1rem 0;
}

.item-price {
  font-size: 1.5rem;
  text-align: right;
}
</style>
