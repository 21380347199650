import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import router from '@/plugins/router'
import toast from '@/plugins/toast'
import storage from '@/plugins/storage'
import HoneybadgerVue from '@honeybadger-io/vue'
const { defaultTo } = require('lodash')

Vue.config.productionTip = false

const honeyBadgerConfig = {
  apiKey: defaultTo(process.env.VUE_APP_HONEYBADGER_API_KEY, 'project api key'),
  environment: defaultTo(process.env.VUE_APP_ENVIRONMENT, 'development'),
  revision: defaultTo(process.env.VUE_APP_BUILD_VERSION, 'local')
}

Vue.use(HoneybadgerVue, honeyBadgerConfig)

new Vue({
  vuetify,
  router,
  toast,
  storage,
  render: (h) => h(App)
}).$mount('#app')
